<template>
  <b-card
    v-if="accommodationOptions"
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <h3> Inventaire - Hébergement</h3>
      </div>

    </b-card-header>

    <!-- body -->
    <b-card-body class="mt-2">
        <div class="mb-1 ">
          <b-row>
            <b-col cols="12">
              <h5>Sommaire des chambres</h5>
            </b-col>
          </b-row>
          <b-row class="text-center mx-0">
            <b-col
              cols="4"
              class=" border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Disponibles
              </b-card-text>
              <h3 class="font-weight-bolder mb-0 text-success">
                {{totalRemainingRooms}}
              </h3>
            </b-col>

            <b-col
              cols="4"
              class=" border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Réservées
              </b-card-text>
              <h3 class="font-weight-bolder mb-0 text-primary">
                {{totalRooms - totalRemainingRooms}}
              </h3>
            </b-col>
            <b-col
              cols="4"
              class=" d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0 ">
                Initial
              </b-card-text>
              <h3 class="font-weight-bolder mb-0 text-primary">
                {{totalRooms}}
              </h3>
            </b-col>
          </b-row>
        </div>
        <hr>
        <b-row class="mb-1 mt-4">
          <b-col cols="12">
            <h4 class=" text-primary">Détails par option</h4>
          </b-col>
        </b-row>

        <div
          v-for="accommodation in accommodationOptions"
          :key="accommodation.id"
        >
          <div
            v-for="room in accommodation.rooms"
            :key="room.id"
          >
            <div
              class="earnings-card"
            >
              <b-row>
                <b-col cols="12" >
                  <div class="float-left">
                    <h5>{{room.room_category}} - <i class="text-muted">{{accommodation.title}}</i></h5>
                  </div>
              <b-button variant="outline-primary" size="sm" class="float-right " hidden >
                <feather-icon
                  icon="EditIcon"
                  size="14"
                  class="cursor-pointer"
                />
                Ajuster
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <div class="font-small-2">
                    Disponibles
                  </div>
                  <h4 class="mb-1">
                    {{room.room_remaining_inventory.remaining_places}}
                  </h4>
                </b-col>
                <b-col cols="6">
                  <div class="font-small-2">
                    Initial
                  </div>
                  <h4 class="mb-1">
                    {{room.inventory}}
                  </h4>
                </b-col>
              </b-row>
              <b-card-text class="text-secondary font-small-3">
                <span class="font-weight-bolder">{{room.inventory - room.room_remaining_inventory.remaining_places}}</span><span> chambres ont été réservées.</span>
                <div>
                  <span> Date de relâche: <b>{{currentGroup.booking_limit}}</b></span>
                </div>
              </b-card-text>
            </b-col>
            <b-col cols="6">
              <!-- chart -->
              <InventoryGraph :data-series="[(room.inventory - room.room_remaining_inventory.remaining_places), room.room_remaining_inventory.remaining_places]" />
            </b-col>
          </b-row>
            </div>
            <hr class="my-2">

          </div>
        </div>


    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader,BAvatar, BCardTitle, BCardText, BCardBody, BMedia, BImg,    BCol,
    BRow, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import InventoryGraph from './InventoryGraph.vue'

import groups from '@mocks/mockGroups'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BCol,
    BRow,
    BMedia,
    BButton,
    BImg,
    VueApexCharts,
    BAvatar,
    InventoryGraph
  },
  props: ['accommodationOptions','currentGroup'],
  data() {
    return {
      accommodations: this.accommodationOptions
    }
  },
  computed: {
    totalRooms() {
      let totalRooms = 0;

      if (this.accommodations && Array.isArray(this.accommodations)) {
        this.accommodations.forEach(option => {
          totalRooms = totalRooms + option.rooms.reduce((total, obj) => obj.inventory + total,0);
        });
      }

      return totalRooms;
    },
    totalRemainingRooms() {
      let totalRemainingRooms = 0;

      if (this.accommodations && Array.isArray(this.accommodations)) {
        this.accommodations.forEach(option => {
          totalRemainingRooms = totalRemainingRooms + option.rooms.reduce((total, obj) => obj.room_remaining_inventory.remaining_places + total,0);
        });
      }

      return totalRemainingRooms;
    }
  },
  watch: {
    accommodationOptions: {
      handler(newAccommodations) {
        this.accommodations = newAccommodations;
      },
      deep: true
    }
  }
}
</script>

export default {

  AIRLINES: {
    air_transat: { 
      name: "Air Transat",
      logo: require('@/assets/images/airlines_logos/TSC.png') 
    },
    transat: { 
      name: "Air Transat",
      logo: require('@/assets/images/airlines_logos/TSC.png') 
    },
    "Air Transat": {
      name: "Air Transat",
      logo: require('@/assets/images/airlines_logos/TSC.png') 
    },
    "Air Canada":{ 
      name: "Air Canada",
      logo: require('@/assets/images/airlines_logos/ACA.png') 
    },
    "Air France":{ 
      name: "Air France",
      logo: require('@/assets/images/airlines_logos/AFR.png') 
    },
    "Turkish Airlines":{ 
      name: "Turkish Airlines",
      logo: require('@/assets/images/airlines_logos/THY.png') 
    },
    "Royal Air Maroc": { 
      name: "Royal Air Maroc",
      logo: require('@/assets/images/airlines_logos/RAM.png') 
    },
    "West Jet": {
      name:"West Jet",
      logo: require('@/assets/images/airlines_logos/WJA.png')
    },
    "Delta Airlines": {
      name:"Delta Airlines",
      logo: require('@/assets/images/airlines_logos/DAL.png')
    },
    "United Airlines": {
      name:"United Airlines",
      logo: require('@/assets/images/airlines_logos/UAL.png')
    },
    "Southwest Airlines": {
      name:"Southwest Airlines",
      logo: require('@/assets/images/airlines_logos/SWA.png')
    },
    "American Airlines": {
      name:"American Airlines",
      logo: require('@/assets/images/airlines_logos/AAA.png')
    },
    "Lufthansa": {
      name:"Lufthansa",
      logo: require('@/assets/images/airlines_logos/DLH.png')
    },
    "British Airways": {
      name:"British Airways",
      logo: require('@/assets/images/airlines_logos/BAW.png')
    },
    "KLM": {
      name: "KLM",
      logo: require('@/assets/images/airlines_logos/KLM.png')
    },
    "China Southern Airlines": {
      name:"China Southern Airlines",
      logo: require('@/assets/images/airlines_logos/CSN.png')
    },
    "Qatar Airlines": {
      name:"Qatar Airlines",
      logo: require('@/assets/images/airlines_logos/QTR.png')
    },
    "Korean Air": {
      name:"Korean Air",
      logo: require('@/assets/images/airlines_logos/KAL.png')
    },
    "Air New Zealand": {
      name:"Air New Zealand",
      logo: require('@/assets/images/airlines_logos/ANZ.png')
    },
    "Japan Airlines": {
      name:"Japan Airlines",
      logo: require('@/assets/images/airlines_logos/JAL.png')
    },
    "Cathay Pacific Airways": {
      name:"Cathay Pacific Airways",
      logo: require('@/assets/images/airlines_logos/CPA.png')
    },
    "Alaska Airlines": {
      name:"Alaska Airlines",
      logo: require('@/assets/images/airlines_logos/ASA.png')
    },
    "Avianca airlines": {
      name:"Avianca airlines",
      logo: require('@/assets/images/airlines_logos/AVA.png')
    },
    "LAN airlines": {
      name:"LAN airlines",
      logo: require('@/assets/images/airlines_logos/LAN.png')
    },
    "TAM airlines": {
      name:"TAM airlines",
      logo: require('@/assets/images/airlines_logos/TAM.png')
    },
    "Qantas": {
      name:"Qantas",
      logo: require('@/assets/images/airlines_logos/QFA.png')
    },
    "Air Tahiti Nui": {
      name:"Air Tahiti Nui",
      logo: require('@/assets/images/airlines_logos/THT.png')
    },
    "TAP airlines": {
      name:"TAP airlines",
      logo: require('@/assets/images/airlines_logos/TAP.png')
    },
    
  },

}
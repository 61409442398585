<template>
    <b-modal
      id="modal-select2"
      title="Exporter la liste des passagers"
      ok-title="Générer le rapport"
      cancel-title="Annuler"
      
      :ok-disabled="!haveFieldSelected"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="generateCSV"
    >
      <b-form>
        <b-row class="mt-2">
            <b-col md="12">
              <h1 class="section-label mx-0 mb-2">
                Champs à exporter
              </h1>
          </b-col>
          <b-col md="12">
           <b-alert :show=!haveFieldSelected variant="warning">
            <p class="p-1">
              Un champs doit être sélectionné pour générer le rapport.
            </p>
          </b-alert>
          </b-col>
          <b-col md="4">

              <b-form-group
                label="Informations des voyageurs"
                label-for="transport-options"
                
              >
              <div class="demo-inline-spacing">
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="option.selectedTravelFields"
                name="flavour-2"
                stacked
              >
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" name="flavour-2" class="custom-control-input" checked disabled >
                  <label class="custom-control-label">Nom complet</label>
                </div>
                <b-form-checkbox
                  v-for="field in travelFields"
                  :key="field.value"
                  :value="field.value"
                >
                {{field.label}}
                </b-form-checkbox>
              </b-form-checkbox-group>
              </div>

              </b-form-group>
          </b-col>
          <b-col md="4">

              <b-form-group
                label="Informations de réservation"
                label-for="transport-options"
                
              >
              <div class="demo-inline-spacing">
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="option.selectedReservationFields"
                name="flavour-2"
                stacked
              >
                <b-form-checkbox
                  v-for="field in reservationFields"
                  :key="field.value"
                  :value="field.value"
                >
                {{field.label}}
                </b-form-checkbox>
              </b-form-checkbox-group>
              </div>

              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4">
              <b-form-checkbox
                  key="select_all"
                  @change='selectAllFields'
                >
                Sélectionner tout
                </b-form-checkbox>
          </b-col>
        </b-row>
        <!--
        <b-row>
          <b-col>
          <draggable
            :list="list2"
            :group="{name: 'tags'}"
            class="mb-1 cursor-move"
          >
            <b-badge
              v-for="(listItem, index) in option.selectedReservationFields"
              :key="index"
              class="mr-25"
            >
              {{ listItem }}
            </b-badge>
            <br>
          </draggable>
          </b-col>
        </b-row>
        -->

      </b-form>
    </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BModal, VBModal, BBadge, BForm, BFormInput, BFormGroup, BCardText,BRow,BCol, BCard,BInputGroup, 
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
  BAlert,
  BTabs,
  BFormFile,
  BTab,
  BFormInvalidFeedback,
  
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import groupCreationConfig from '@core/data/groupCreationConfig.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { reactive } from '@vue/composition-api'
import {useApplicationContext} from "@/shared/composables/use-application-context";


export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    BBadge,
    vSelect,
    BRow,
    BCol,
    BInputGroup, 
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BAlert,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    draggable,
    Prism
    },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const { router} = useApplicationContext();    
    const option = reactive({
      selectedTravelFields: [],
      selectedReservationFields: []
    })
    const travelFields = reactive([
        //{ label: "Prénom", value: "first_name"},
        //{ label: "Nom", value: "last_name"},
        { label: "Genre", value: "gender"},
        { label: "Date de naissance", value: "birthday"},
        // { label: "Citoyenneté", value: "CITIZENSHIP"},
        { label: "Code postal", value: "postal_code"},
        { label: "Numéro de téléphone", value: "phone"},
        { label: "Courriel", value: "email"},
        //{ label: "Ville", value: "city"},
        // { label: "Pays", value: "country"}
        
      ]);

      const reservationFields = reactive([
        { label: "No de réservation", value: "reservation_number"},
        { label: "Occupation", value: "occupation"},
        { label: "Forfait", value: "package"},
        { label: "Nom du contact d'urgence", value: "emergency_contact_name"},
        { label: "Numéro de téléphone du contact d'urgence", value: "emergency_contact_phone"},
        { label: "Allergies", value: "allergies"},
        { label: "Conseiller", value: "agent"},
        { label: "Courriel du conseiller", value: "agent_email"},
        { label: "Notes", value: "notes"},
        { label: "Information supplémentaire", value: "extra_information"},
        { label: "Date de réservation", value: "created_at"},
        { label: "Numéro de l'agence", value: "agency_no"},
        { label: "Nom de l'agence", value: "agency"},
        { label: "Numéro de téléphone de l'agence", value: "agency_phone"},
        { label: "Numéro de tour-opérateur", value: "to_number"}

      ]);

    const selectAllFields = (checked) => {
      if (checked) {
        travelFields.forEach(field => {
          option.selectedTravelFields.push(field.value)
        });

        reservationFields.forEach(field => {
          option.selectedReservationFields.push(field.value)
        });

      }
    }

    return {travelFields, reservationFields, selectAllFields, option}
  },
  data() {
    return {
      groupCreationConfig,
    }
  },
  methods: {
    generateCSV () {
      const travelerFieldsInReservationFieldList = ['emergency_contact_name', 'emergency_contact_phone', 'allergies'];

      let travelerFields = this.option.selectedTravelFields;

      if (!this.option.selectedReservationFields) {
        this.option.selectedReservationFields = [];
      }
      
      let travelerFieldsFromReservation = this.option.selectedReservationFields.filter(x => travelerFieldsInReservationFieldList.includes(x));
      

      travelerFields = travelerFields.concat(travelerFieldsFromReservation);
      
     
      const reservationFields = this.option.selectedReservationFields.filter(x => !travelerFieldsInReservationFieldList.includes(x))


      this.$emit('export', {
        'travel_fields': travelerFields,
        'reservation_fields': reservationFields
      })
    }
  },
  computed: {
    haveFieldSelected() {
      let haveFields = false;
      if (this.option.selectedReservationFields?.length > 0 || this.option.selectedTravelFields?.length > 0) {
        haveFields = true
      }

      return haveFields;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-modal
    id="new-public-reservation-modal"
    title="Nouvelle réservation"
    ok-title="Créer"
    cancel-title="Annuler"
    cancel-variant="outline-secondary"
    size="lg"
    @ok="$emit('submit', formData)"
    @cancel="resetForm"
    no-close-on-backdrop
  >
    <b-form>
      <b-row>
        <b-col md="12">
          <p>Veuillez remplir le formulaire suivant pour débuter la réservation de votre forfait.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Votre adresse courriel"
            label-for="request-type"
          >
            <b-form-input v-model="formData.email"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Votre numéro de téléphone"
            label-for="request-type"
          >
            <b-form-input v-model="formData.phone"/>
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nom de votre agent de voyage"
            label-for="description"
          >
          <v-select
              id="request-type"
              ref="request-type"
              v-model="formData.agent_id"

              :options="agents"
              label="full_name"
              :reduce="text => text.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select';
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BFormTags,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue';

import {useLocalisation} from "@/shared/composables/use-localisation";

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
    BInputGroup,
    BFormTags,
    BRow,
    BCol,
    BFormTextarea
  },

  props: {
    agents: {
      type: Array,
      default() {
        return [
          {
            text: 'Jean Lalande',
            value: '4dd56248-7a2e-4c36-9521-07ff78df9f40'
          }
        ]
      }
    },
    currentAgentId: {
      type: String
    }
  },
  setup(props) {
    const {t} = useLocalisation();

    return {
      t,
    }
  },
  data() {
    return {
      formData: {
        email: '',
        phone: '',

        agent_id: this.currentAgentId
      },
    };
  },
  methods: {
    resetForm() {},
  },
};
</script>

<template>

  <b-card
    v-if="transportOptions"
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <h3>Inventaire - Transport</h3>
      </div>
    </b-card-header>
    <!-- body -->
    <b-card-body class="mt-2">

        <div class="mb-1 ">
          <b-row>
            <b-col cols="12">
              <h5>Sommaire des sièges</h5>
            </b-col>
          </b-row>
          <b-row class="text-center mx-0">
            <b-col
              cols="4"
              class=" border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Disponibles
              </b-card-text>
              <h3 class="font-weight-bolder mb-0 text-success">
                {{totalRemainingSeats}}
              </h3>
            </b-col>

            <b-col
              cols="4"
              class=" border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Réservés
              </b-card-text>
              <h3 class="font-weight-bolder mb-0 text-primary">
                {{totalSeats-totalRemainingSeats}}
              </h3>
            </b-col>
            <b-col
              cols="4"
              class=" d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                Initial
              </b-card-text>
              <h3 class="font-weight-bolder mb-0 text-primary">
                {{totalSeats}}
              </h3>
            </b-col>
          </b-row>
        </div>
        <hr>
        <b-row class="mb-1 mt-4">
          <b-col cols="12">
            <h4 class=" text-primary">Détails par option</h4>
          </b-col>
        </b-row>
        <div
          v-for="transport in transportOptions"
          :key="transport.name"
        >
        <div class="earnings-card">
          <b-row>
            <b-col cols="12" >
              <h5 class="float-left">{{transport.name}}</h5>
              <b-button variant="outline-primary" size="sm" class="float-right " hidden>
                <feather-icon
                  icon="EditIcon"
                  size="14"
                  class="cursor-pointer"
                />
                Ajuster
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <div class="font-small-2">
                    Disponibles
                  </div>
                  <h3 class="mb-1">
                    {{transport.remaining_seats}}
                  </h3>
                </b-col>
                <b-col cols="6">
                  <div class="font-small-2">
                    Initial
                  </div>
                  <h3 class="mb-1">
                    {{transport.seats}}
                  </h3>
                </b-col>
              </b-row>
              <b-card-text class="text-secondary font-small-3">
                <div>
                  <span><b>{{transport.seats-transport.remaining_seats}}</b> sièges ont été réservés.</span>
                </div>
                <div>
                  <span> Date de relâche: <b>{{currentGroup.booking_limit}}</b></span>
                </div>
              </b-card-text>
            </b-col>
            <b-col cols="6">
              <!-- chart -->
              <InventoryGraph :data-series=[transport.seats-transport.remaining_seats,transport.remaining_seats] />

            </b-col>
          </b-row>
        </div>
        <hr class="my-2">
        </div>



    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader,BAvatar, BCardTitle, BCardText, BCardBody, BMedia, BImg,    BCol,
    BRow, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import {ref, reactive, watch, toRefs} from '@vue/composition-api'
import InventoryGraph from './InventoryGraph.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BCol,
    BRow,
    BMedia,
    BButton,
    BImg,
    VueApexCharts,
    BAvatar,
    InventoryGraph
  },
  props: {
      transportOptions: {
        required: true
      },
      currentGroup: {
        required:true
      }
    },
  data() {
    return {
      localTransportOptions: this.transportOptions
    }
  },
  computed: {
    totalSeats() {
      let totalSeats = 0;

      if (this.localTransportOptions && Array.isArray(this.localTransportOptions)) {
        totalSeats = totalSeats + this.localTransportOptions.reduce((total, obj) => obj.seats + total,0);
      }

      return totalSeats;
    },
    totalRemainingSeats() {
      let totalRemainingSeats = 0;

      if (this.localTransportOptions && Array.isArray(this.localTransportOptions)) {
        totalRemainingSeats = totalRemainingSeats + this.localTransportOptions.reduce((total, obj) => obj.remaining_seats + total,0);
      }

      return totalRemainingSeats;
    },
  },
  watch: {
    transportOptions: {
      handler(newTransportOptions) {
        this.localTransportOptions = newTransportOptions;
      },
      deep: true
    }
  }
}
</script>

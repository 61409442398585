<template>
  <b-row class="match-height">
    <b-col         
      lg="12"
      md="12"
    >
      <group-create-accommodation-options :can-edit="canEdit" :group-id="currentGroupId" />

    </b-col>

  </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel } from 'bootstrap-vue'
import store from '@/store/index'

import GroupCreateAccommodationOptions from "@/modules/group/creation-wizard/groupCreateAccommodationOptions/GroupCreateAccommodationOptions";



export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    GroupCreateAccommodationOptions

  },
  props: ['accommodationOptions', 'currentGroupId', 'canEdit'],

  data() {
    return {
      currentAccommodationOption: {
        index: null
      }
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    showAddOptionModal() {
      this.$refs['accommodation-option-modal'].$children[0].show()
    },
    showAddRoomModal(accommodationOptionIndex) {
      
      this.currentAccommodationOption.index = accommodationOptionIndex;

      this.$refs['create-room-option-modal'].$children[0].show()
    },
    showEditOptionModal(index) {
      this.$refs["edit-accommodation-option-modal-"+index][0].$children[0].show()
      this.currentAccommodationOption.index = index;
    },
    showEditRoomModal(index) {
      this.$refs[`edit-room-option-modal-${index}`][0].$children[0].show()
      this.currentAccommodationOption.index = index;
    },
    createGroupAccommodationOption(newOption) {
      let accommodationOption = Object.assign({}, newOption)
      let currentGroupId = store.getters["app-groups/getCurrentGroupId"]
          this.accommodationOptions.push(accommodationOption);

      return new Promise((resolve,reject) => {     
        
       groupAccommodationService.addAccommodationOption(currentGroupId, accommodationOption).then(response => {


          //this.displayCreationConfirmation();
          resolve(true)
        }).catch(error => {
          console.error(error)
          //this.displayCreationConfirmation(error)
          reject(true)
        })
      
      });
    },
    createNewRoom(newRoom) {
      let room = Object.assign({}, newRoom);
      this.accommodationOptions[this.currentAccommodationOption.index].rooms.push(room)
    },
    editAccommodationOption(editedOption) {
      alert(editedOption)
      //TODO
    },
    editRoomOption(editedRoomOption) {
      alert(editedRoomOption)
      //TODO
    }
  },
  computed: {
    hasOneOption() {
      try {
        return this.accommodationOptions.length > 0
      } catch {
        return false;
      }
    }
  }
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color:white;
  }

  .flight-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }

  .airport-code {
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  .flight-time {
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
  }

  .airport-city, .flight-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 2px
  }

  .small-top-padding {
    padding: 0;
    padding-top:10px;
  }


</style>

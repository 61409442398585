<template>
  <div>

  <b-card>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end ">
      <b-form-group>
        <div class="d-flex align-items-center ">
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
          <b-button
            variant="primary"
            class="ml-1"
            @click="showExportModal"
          >
            <span class="text-nowrap">Exporter</span>
          </b-button>
        </div>
        
      </b-form-group>

    </div>

    <code v-if=debugMode>roomingListTravelers: {{roomingListTravelers}}</code>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="roomingListTravelers"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      styleClass="vgt-table striped">
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'traveler'"
          class="text-nowrap"
        > 
          <span class="text-nowrap"> {{ props.row.traveler_name }}</span>
        </span>

        <!-- Column: Reservation Number -->
        <span
          v-if="props.column.field === 'reservation_number'"
          class="text-nowrap"
        >
          
          <span class="text-nowrap link">{{ props.row.reservation_number }}</span>
        </span>

        <!-- Column: status -->
        <span v-else-if="props.column.field === 'occupation'">
          <b-badge :variant="statusVariant(props.row.occupation)">
            {{ statusLabel(props.row.occupation) }}
          </b-badge>
        </span>

      <!-- Column: type -->
        <span v-else-if="props.column.field === 'type'">
          <feather-icon
              :icon="typeIcons(props.row.type)"
              size="18"
              class="mr-50"
              :class="`text-${typeVariant(props.row.type)}`"
            />
            <span class="align-text-top text-capitalize">{{ typeLabel(props.row.type) }}</span>
        </span>

        <!-- Column: gender -->
        <span v-else-if="props.column.field === 'gender'">
            {{ genderLabel(props.row.gender) }}
        </span>

        <!-- Column: Action -->
      
        <span v-else-if="props.column.field === 'action'">
          <b-button
            variant="outline-primary"
            class="ml-1"
            size="sm"
          >
            <span class="text-nowrap">Détails</span>
          </b-button>

        </span>
    
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Indiquer 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> des {{ props.total }} données </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <group-rooming-list-export-modal ref="export-rooming-list" @export="exportCSV"/>
  </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { ref } from '@vue/composition-api'
import GroupRoomingListExportModal from './GroupRoomingListExportModal'
import {useApplicationContext} from "@/shared/composables/use-application-context";
import { GroupRoomingListService } from '@/views/groups/services/GroupRoomingListService.js'
export const groupRoomingListService = new GroupRoomingListService();

export default {
  components: {
    BCard,
    BRow, 
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    GroupRoomingListExportModal
  },
  props: {
    currentGroupId: {
      type: String,
      required: true,
    },
    currentGroup: {
      type: Object
    }
  },
  setup(props) {
    const {refOf, router} = useApplicationContext();
    const statusOptions = [
      { label: 'simple', value: 'Simple' },
      { label: 'double', value: 'Double' },
      { label: 'triple', value: 'Triple' },
      { label: 'quadruple', value: 'quadtruple' },
    ]

    const roomingListTravelers = ref([])

    const getRoomingList = () => {
      groupRoomingListService.getRoomingList(props.currentGroupId).then(response => {
          roomingListTravelers.value = response;
        })
    }

    getRoomingList()
    
    const showExportModal = () => {
      refOf('export-rooming-list').$children[0].show();
    }
    const debugMode = ref(false)
    if (router.currentRoute.query && router.currentRoute.query.debug == 'true') {
    debugMode.value = true;
    }

    return {
      statusOptions,
      roomingListTravelers,
      showExportModal,
      debugMode
    }
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Index',
          field: 'index',
        },
        {
          label: 'No. de réservation',
          field: 'reservation_number',
        },
        {
          label: 'Nom du voyageur',
          field: 'traveler',
        },
        {
          label: "Genre",
          field: 'gender',
        },
        {
          label: 'Date de naissance',
          field: 'dob',
        },
        {
          label: 'Occupation',
          field: 'occupation',
        },
        {
          label: 'Forfait',
          field: 'package_name',
        },
      ],
      searchTerm: '',
      typeOptions: [
        'TRANSPORT',
        'all_inclusive'
      ],
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      planOptions: [],
     
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        SINGLE    : 'light-danger',
        DOUBLE        : 'light-success',
        TRIPLE        : 'light-info',
        QUADRUPLE        : 'light-warning',
      }
      return status => statusColor[status]
    },

    statusLabel() {
      const statusLabel = {
        SINGLE    : 'Simple',
        DOUBLE        : 'Double',
        TRIPLE: 'Triple',
        QUADRUPLE: 'Quadruple',
        DRAFT     : 'Draft',
      }
      return status => statusLabel[status]
    },

    genderLabel() {
      const genderLabel = {
        'WOMAN' : 'Femme',
        'MAN': 'Homme',
        'TRANS': 'Autre'
      };
      return gender => genderLabel[gender]
    },

    typeVariant() {
      const typeColor = {
        TRANSPORT        : 'danger',
        all_inclusive : 'success',
      }
      return type => typeColor[type]
    },

   typeLabel() {
      const typeLabel = {
        TRANSPORT        : 'Transport',
        all_inclusive : 'All Inclusive',
      }
      return type => typeLabel[type]
    },

    typeIcons() {
      const typeIcons = {
        TRANSPORT         : 'DropletIcon',
        all_inclusive  : 'SunIcon',
      }
      return type => typeIcons[type]
    },
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return character => Math.round(parseInt(character.slice(-1),UUID_BASE)/BASE)
    }
    ,
    avatarColors() {
      
      const avatarColors = {
        "1":"primary",
        "2":"dark",
        "3":"secondary",
        "4":"success",
        "5":"danger",
        "6":"warning",
        "7":"info",
        "8":"secondary",
      }
      return character => avatarColors[character]
    }


  },
  methods: {
    async exportCSV(fields) {
      const response = await groupRoomingListService.exportRoomingList(this.currentGroupId, fields);
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
    
      fileLink.href = fileURL;
      const today = new Date();
      let name = `Liste de passagers - ${this.currentGroup.name} - ${today.toLocaleDateString()}.xlsx`;
      fileLink.setAttribute('download', name);
      document.body.appendChild(fileLink);
    
      fileLink.click();
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

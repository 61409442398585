<template>
  <div>
    <!-- chart -->
    <vue-apex-charts
      height="120"
      :options="earningsChart.chartOptions"
      :series=dataSeries
    />

  </div>
</template>

<script>
import {
  BCard, BCardHeader,BAvatar, BCardTitle, BCardText, BCardBody, BMedia, BImg,    BCol,
    BRow, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BCol,
    BRow,
    BMedia,
    BButton,
    BImg,
    VueApexCharts,
    BAvatar

  },
    props: ['dataSeries'],

  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          labels: ['Réservé', 'Disponibles'],
          stroke: { width: 0 },
          colors: [$themeColors.primary, $themeColors.info],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: '% réservé',
                    formatter: function (w) {
                      let percent = w.globals.seriesTotals[0]/(w.globals.seriesTotals[1]+w.globals.seriesTotals[0]);
                      if(!Number.isFinite(percent)) {
                        percent = 0;
                      }
                      return (parseFloat(percent*100).toFixed(1))
                    }
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  created() {},
  computed: {

  }
}
</script>

<template>
  <b-card class="mt-3">
    <b-row>
      <b-col md="5">
        <b-carousel
          id="carousel-interval"
          controls
          indicators
          :interval="10000"
        >
          <b-carousel-slide :img-src="getGroupImage" />
        </b-carousel>
        <div class="border-warning rounded p-1 mt-2">
          <h4 class="text-warning text-darken-2">
            <b-avatar circle variant="light-warning" size="34">
              <feather-icon icon="AlertCircleIcon" size="18" />
            </b-avatar>
            Exigences voyage
          </h4>
          <p class="text-justify">{{ currentGroup.requirements }}</p>
        </div>
      </b-col>
      <b-col md="5">
        <div class="mb-3">
          <h1>{{ currentGroup.name }}</h1>
          <h4 class="mt-1 text-primary">
            {{ this.formatDate(currentGroup.departure_date, "dd MMMM") }} -
            {{ this.formatDate(currentGroup.return_date, "dd MMMM y") }}
          </h4>
        </div>
        <div v-if="showRegistrationLink">
          <h4 class="mt-2">
            Lien pour client
            <b-avatar
              v-if="accountingAssociationsReady"
              v-b-tooltip.hover.top.html="
                'Vos fiches comptables sont complétées.'
              "
              rounded
              variant="light-success"
              size="34"
            >
              <feather-icon icon="CheckCircleIcon" size="12" />
            </b-avatar>
            <b-avatar
              v-else
              v-b-tooltip.hover.top.html="
                'Une ou plusieurs fiches comptables sont incomplètes. Consultez l\'onglet Facturation.'
              "
              rounded
              variant="light-warning"
              size="34"
            >
              <feather-icon icon="AlertTriangleIcon" size="12" />
            </b-avatar>
          </h4>
          <b-input-group class="w-75">
            <b-form-input
              v-on:focus="$event.target.select()"
              ref="registrationLink"
              :value="registrationLink"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                v-b-tooltip.hover
                title="Copier le lien"
                size="small"
                variant="outline-secondary"
                @click="copyRegistrationLink($event)"
              >
                <feather-icon icon="ClipboardIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <h4 class="mt-2">Description</h4>
        <p class="text-justify" style="white-space: pre-line">
          {{ currentGroup.description }}
        </p>
        <h4 class="">Informations supplémentaires</h4>
        <p class="text-justify" style="white-space: pre-line">
          {{ currentGroup.extra_information }}
        </p>
        <p v-if="currentGroup.link">
          Pour plus d'informations, visitez
          <a :href="currentGroup.link" target="_blank">{{
            currentGroup.link
          }}</a
          >.
        </p>
        <b-row>
          <b-col md="6">
            <h5 class="mt-2 text-secondary">Inclusions</h5>

            <ul style="list-style: none; padding-inline-start: 2px">
              <li v-for="inclusion in currentGroup.inclusions" :key="inclusion">
                <span class="bullet bullet-sm mr-1 bullet-success"></span>
                <small>{{ inclusion }}</small>
              </li>
            </ul>
          </b-col>
          <b-col md="6">
            <h5 class="mt-2 text-secondary">Exclusions</h5>
            <ul style="list-style: none; padding-inline-start: 2px">
              <li v-for="exclusion in currentGroup.exclusions" :key="exclusion">
                <span class="bullet bullet-sm mr-1 bullet-danger"></span>
                <small>{{ exclusion }}</small>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="2">
        <b-row v-if="showReservationButton" class="mb-2">
          <b-col>
            <div>
              <b-button
                :disabled="!canReserved"
                size="lg"
                variant="warning"
                @click="redirectToPkg"
                >Réserver</b-button
              >
            </div>
          </b-col>
        </b-row>

        <b-row v-if="showStatusSection">
          <b-col>
            <div>
              <h5 class="mb-2 text-muted center float-left">Statut</h5>
              <b-button
                v-if="
                  canViewBasedOnRole([
                    Roles.ORGANIZATION_ADMIN.role,
                    Roles.ORGANIZATION_COORDINATOR.role,
                  ])
                "
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle float-right"
                @click="openStatusModal"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div v-if="showStatusSection" class="mb-3">
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon icon="UserIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Groupe</h5>
              <b-badge
                class="pl-2 pr-2 text-uppercase"
                :variant="statusVariant(currentGroup.status)"
                >{{ t(`groups.status.${currentGroup.status}`) }}</b-badge
              >
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-info" size="34">
                <feather-icon icon="ActivityIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Vente</h5>
              <b-badge
                class="pl-2 pr-2 text-uppercase"
                :variant="saleStatusVariant(currentGroup.sale_status)"
                >{{
                  t(`groups.sale_status.${currentGroup.sale_status}`)
                }}</b-badge
              >
            </b-media-body>
          </b-media>
          <b-media
            v-if="
              canViewBasedOnRole([
                Roles.ORGANIZATION_ADMIN.role,
                Roles.ORGANIZATION_COORDINATOR.role,
              ])
            "
            no-body
            class="mb-1"
          >
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-warning" size="34">
                <feather-icon icon="LockIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Accès</h5>
              <b-badge
                class="pl-2 pr-2 text-uppercase"
                :variant="accessStatusVariant(currentGroup.access_status)"
                >{{ accessStatusLabel(currentGroup.access_status) }}</b-badge
              >
            </b-media-body>
          </b-media>
        </div>

        <br />
        <h5 class="mb-2 text-muted center">Information</h5>
        <div class="mb-3">
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon icon="UserIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Accompagnateur</h5>
              <small v-if="currentGroup.guide != null">{{
                currentGroup.guide
              }}</small>
              <small v-else>Aucun</small>
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-info" size="34">
                <feather-icon icon="ActivityIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Type de voyage</h5>
              <small>{{ t(`groups.type.${currentGroup.type}`) }}</small>
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-warning" size="34">
                <feather-icon icon="BarChartIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Rythme</h5>
              <small>{{ t(`groups.rythme.${currentGroup.difficulty}`) }}</small>
            </b-media-body>
          </b-media>
        </div>
        <h5 class="mb-2 text-muted center">Dates importantes</h5>
        <div class="mb-3">
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-dark" size="34">
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Limite réservation</h5>
              <small>{{
                this.formatDate(currentGroup.booking_limit, "dd MMMM y")
              }}</small>
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-danger" size="34">
                <feather-icon icon="ActivityIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Paiement final</h5>
              <small>{{
                this.formatDate(currentGroup.payment_limit, "dd MMMM y")
              }}</small>
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1">
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-success" size="34">
                <feather-icon icon="DollarSignIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body style="margin: auto">
              <h5 class="mb-0">Dépôt</h5>
              <small>{{ currentGroup.deposit_amount }}$</small>
            </b-media-body>
          </b-media>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <h4 class="mb-2" ref="packages_list">Forfaits offerts</h4>
        <div v-if="currentPackages.length == 0" class="mt-4">
          <b-card
            v-if="
              canViewBasedOnRole([
                Roles.ORGANIZATION_ADMIN.role,
                Roles.ORGANIZATION_COORDINATOR.role,
              ])
            "
            title="Il n'existe aucun forfait pour le moment"
            header-tag="header"
            body-class="text-center"
            class="mx-5"
          >
            <b-card-text>
              Ajouter votre premier forfait en navigant à la section Forfait ou
              simplement appuyer sur le bouton Ajouter ci-dessous.
            </b-card-text>

            <b-button
              variant="primary"
              class="ml-1"
              :to="{ name: 'group-add-package' }"
            >
              <span class="text-nowrap">+ Ajouter</span>
            </b-button>
          </b-card>
          <b-card
            v-else
            title="Il n'existe aucun forfait pour le moment"
            header-tag="header"
            body-class="text-center"
            class="mx-5"
          >
            <b-card-text>
              Les forfaits apparaîtront dès qu'ils seront disponibles
            </b-card-text>
          </b-card>
        </div>
        <div v-for="pkg in currentPackages" :key="pkg.id">
          <PackageCard
            :group-id="currentGroup.id"
            :can-reserved="canReserved"
            :show-reservation-button="showReservationButton"
            :current-accommodations="currentAccommodations"
            :is-package-unavailable="unavailablePackages.includes(pkg.id)"
            :pkg="pkg"
            :available-agents="availableAgents"
            :current-agent-id="defaultAgentId"
            :show-accommodation-tab="showPackageAccommodationTab"
            @book="$emit('book', $event)"
          />
        </div>
      </b-col>
    </b-row>
    <group-status-modal
      v-if="showStatusSection"
      :current-group="currentGroup"
      @monica-group-saved="updateGroupStatuses"
      ref="group-status-modal"
    />
  </b-card>
</template>

<script>
import {
  VBTooltip,
  BInputGroupAppend,
  BInputGroupText,
  BFormInput,
  BInputGroup,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCarousel,
  BCarouselSlide,
  BBadge,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItemButton,
  BDropdownDivider,
} from "bootstrap-vue";
import PackageCard from "./PackageCard.vue";
import store from "@/store/index";
import { GroupService } from "../../services/GroupService.js";
export const groupService = new GroupService();
import { onMounted, watch, computed } from "@vue/composition-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { reactive, ref } from "@vue/composition-api";
import GroupStatusModal from "./GroupStatusModal.vue";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import Ripple from "vue-ripple-directive";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import { useLocalisation } from "@/shared/composables/use-localisation";
import Tenant from "@/plugins/tenant";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BBadge,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
    GroupStatusModal,
    PackageCard,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
  },
  props: {
    currentGroup: Object,
    currentPackages: Array,
    currentAccommodations: Array | Object,
    unavailablePackages: Array,
    availableAgents: Array,
    defaultAgentId: String,
    showReservationButton: {
      type: Boolean,
      default: true,
    },
    showStatusSection: {
      type: Boolean,
      default: true,
    },
    showPackageAccommodationTab: {
      type: Boolean,
      default: true,
    },
    showRegistrationLink: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { refOf } = useApplicationContext();
    const { store } = useApplicationContext();
    const { t } = useLocalisation();
    const { displayInfoMessage } = useToastNotification();

    let isTransat = ref(true);

    const currentAuthUser = ref({});
    currentAuthUser.value =
      store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];

    const tenantId = Tenant.resolveTenantId();
    if (tenantId === "transat") {
      isTransat.value = true;
    } else {
      isTransat.value = false;
    }

    const status = ["CANCELLED", "COMPLETED", "DRAFT", "PUBLISHED", "ARCHIVED"];

    const canViewBasedOnRole = (roles) => {
      try {
        let role = currentAuthUser.value.role.role;
        return roles.includes(role);
      } catch {
        return false;
      }
    };

    const canReserved = computed(() => {
      let canReserve = false;

      if (
        props.showReservationButton &&
        props.currentGroup.sale_status == "ON_SALE"
      ) {
        canReserve = true;
      }

      return canReserve;
    });

    const updateGroupStatuses = (data) => {
      refOf("group-status-modal").$children[0].hide();
      props.currentGroup.status = data.status;
      props.currentGroup.access_status = data.accessStatus;
      props.currentGroup.sale_status = data.saleStatus;
    };

    const openStatusModal = (data) => {
      refOf("group-status-modal").$children[0].show();
    };
    return {
      displayInfoMessage,
      status,
      updateGroupStatuses,
      openStatusModal,
      canViewBasedOnRole,
      Roles,
      canReserved,
      t,
    };
  },
  methods: {
    formatDate(date, desiredFormat) {
      return this.$luxon(date, {
        input: { format: "yyyy-MM-dd", zone: "local" },
        output: { format: desiredFormat, locale: "fr" },
      });
    },
    redirectToPkg() {
      var element = this.$refs["packages_list"];
      var offset = element.getBoundingClientRect();

      window.scrollTo({
        top: offset.top,
        behavior: "smooth",
      });
    },
    copyRegistrationLink() {
      this.$refs.registrationLink.focus();
      document.execCommand("copy");
      this.displayInfoMessage("Lien pour client copié!");
    },
  },
  computed: {
    registrationLink() {
      const currentUser =
        store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];

      const basePart = `${window.location.origin}/public/groups/${this.currentGroup.id}`;
      const tokenPart = `token=${this.currentGroup.public_access_token}`;
      const agentPart = `agent=${currentUser.id}`;

      return `${basePart}?${tokenPart}&${agentPart}`;
    },
    getGroupImage() {
      const DEFAULT_IMAGE = require("@/assets/images/group/stock_plane.jpg");

      if (this.currentGroup.images) {
        return this.currentGroup.images;
      }
      return DEFAULT_IMAGE;
    },
    getCurrentGroupTypeLabel() {
      return this.currentGroup.type;
    },
    statusVariant() {
      const statusColor = {
        CANCELLED: "light-danger",
        COMPLETED: "light-secondary",
        DRAFT: "light-info",
        PUBLISHED: "light-success",
        ARCHIVED: "light-warning",
      };
      return (status) => statusColor[status];
    },

    statusLabel() {
      const statusLabel = {
        CANCELLED: "annulé",
        COMPLETED: "completé",
        DRAFT: "brouillon",
        PUBLISHED: "publié",
        ARCHIVED: "archivé",
      };
      return (status) => statusLabel[status];
    },

    saleStatusVariant() {
      const salesStatusColor = {
        UPCOMING: "light-danger",
        ON_SALE: "light-secondary",
        ON_DEMAND: "light-info",
        FULL: "light-success",
        CLOSE: "light-warning",
      };
      return (status) => salesStatusColor[status];
    },

    saleStatusLabel() {
      const salesStatusLabel = {
        UPCOMING: "UPCOMING",
        ON_SALE: "En vente",
        ON_DEMAND: "Sur demande",
        FULL: "Complet",
        CLOSE: "Fermé",
      };
      return (status) => salesStatusLabel[status];
    },

    accessStatusVariant() {
      const accessStatusColor = {
        RESTRICTED: "light-danger",
        UNPUBLISHED: "light-secondary",
        PUBLISHED: "light-success",
      };
      return (status) => accessStatusColor[status];
    },

    accessStatusLabel() {
      const accessStatusLabel = {
        RESTRICTED: "Restreint",
        UNPUBLISHED: "Privé",
        PUBLISHED: "Public",
      };
      return (status) => accessStatusLabel[status];
    },
    accountingAssociationsReady() {
      const accounting = this.$store.state["app-groups"]?.accounting;
      const associations = accounting?.associations;

      return associations
        ? associations.length > 0 &&
            associations.filter((x) => x.status === "COMPLETED").length ===
              associations.length
        : false;
    },
  },
};
</script>

<style lang="scss">
.card-developer-meetup {
  .meetup-header {
    margin-bottom: 2rem;

    .meetup-day {
      text-align: center;
      border-right: 1px solid #d8d6de;
      padding-right: 1.3rem;
      margin-right: 1.3rem;
    }
  }
  .media {
    .avatar .avatar-content {
      width: 34px;
      height: 34px;
    }
    & + .media {
      margin-top: 1.2rem;
    }
  }
  .avatar-group {
    margin-top: 2rem;
  }
}
</style>

<template>

  <b-row>
    
       <b-col md="6" v-for="occupation in currentPackage.occupation_types" :key="occupation.id">
        <b-row>
          <b-col md="12">
            <h5>Occupation {{occupation.type}}</h5>
          </b-col>
          <b-col>
            <div class="mt-2">
              <b-list-group>
                  <b-list-group-item class="dark-line-item" >
              
                      Prix par adulte (taxes incluses)
    
                  </b-list-group-item>
                  <div v-for="pricing in occupation.occupation_type_pricing" :key="'adult-' + occupation.id + '-' + pricing.index + '-' + Math.random() * 1000">
                    <b-list-group-item v-if="pricing.type == 'ADULT'">
                      Adulte #{{pricing.index+1}}
                      <span  style="float:right">${{pricing.price}}</span>
                    </b-list-group-item>
                  </div>
              </b-list-group>
            </div>
            </b-col>
            <b-col v-if="occupation.supported_children_types.length > 0">
            <div class="mt-2">
              <b-list-group>
                  <b-list-group-item class="dark-line-item" >
                    Prix par enfant (taxes incluses)
                  </b-list-group-item>
                  <div v-for="pricing in occupation.occupation_type_pricing" :key="'child-' + occupation.id + '-' + pricing.index + '-' + Math.random() * 1000">
                    <b-list-group-item v-if="pricing.type != 'ADULT'">
                      {{ t(`reservations['${pricing.type}']`) }} #{{pricing.index+1}}
                      <span  style="float:right">${{pricing.price}}</span>
                    </b-list-group-item>
                  </div>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
      </b-col>
  </b-row>
</template>

<script>
import {useLocalisation} from "@/shared/composables/use-localisation";
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel,BCarousel,BCarouselSlide,    BListGroup, 
    BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BCarousel,
    BCarouselSlide,
        BListGroup, 
    BListGroupItem
  },
  props: {
    currentPackage: Object,
  },
  setup() {
    const {t} = useLocalisation();
    return {t}
  },
  data() {
    return {

    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
  }
}
</script>

<style>

  [dir] .list-group-item {
      background-color: #fff;
      border: 1px solid rgba(34, 41, 47, 0.125);
      padding: 5px;
  }

  .dark-line-item, .dark-line-item:hover {
    
    background-color: #015a9c !important;
    color: white;
  }

  .children-pricing-border-top {
    border-top: 1px solid #4b4b4b;
  }
</style>

<template>
  <b-card class="card mt-2 ml-2 mr-2">
    <div class="d-flex justify-content-between">
      <button @click="showGroupPage()" class="btn btn-link p-0">
        <img :src="logo" class="d-block" style="max-width: 9em" />
      </button>

      <div v-if="agent" class="text-right my-auto">
        <!-- <p class="font-weight-bolder mb-0">
                <button @click="showGroupPage()" class="btn btn-link p-0">
                  {{ currentGroup.name }}
                </button>
              </p> -->
        <!-- <p class="mb-0 font-weight-bolder">Information de contact</p> -->
        <p class="mb-0">
          <strong>Votre conseiller voyage</strong>: 
          <span v-if="agent && agent.full_name">{{ agent.full_name }}</span>
          <span v-else>
            {{ agent.first_name }} {{ agent.last_name }}
          </span>
        </p>
        <p class="mb-0"><strong>Agence</strong>: {{ agencyName }}</p>
        <p class="mb-0">
          <strong>Courriel</strong>:
          <a :href="`mailto:${agent.email}`">{{ agent.email }}</a>
        </p>
        <p class="mb-0">
          <strong>Téléphone</strong>:
          <a :href="`tel:${agent.phone_number}`">{{ agent.phone_number }}</a>
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>
import {BCard} from 'bootstrap-vue'
import { $themeConfig } from "@themeConfig";
import store from "@/store";

import { GroupService } from "@/views/groups/services/GroupService.js";
export const groupService = new GroupService();
import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
export const groupReservationService = new GroupReservationService();

export default {
  components: {
    BCard,
  },
  props: {
    agencyName: {
      type: String
    },
    agent: {
        type: Object,
        default() {
            return {}
        }
    },
  },
  data() {
    return {
      currentGroup: {},
      currentGroupId: '',
    }
  },

  setup(props, context) {
    const publicAccessToken = context.root.$route.query.token;
    return {
        publicAccessToken
    }
  },
  created: function () {
    this.currentGroupId = this.$route.params.id;
    this.publicAccessToken = this.$route.query.token;

    groupService
      .getGroup(this.currentGroupId, this.publicAccessToken)
      .then((response) => {
        this.currentGroup = response;
        store.commit("app-groups/setCurrentGroupName", this.currentGroup.name);
        store.commit("app-groups/setCurrentGroupId", this.currentGroup.id);
      });
  },
  computed: {
    logo() {
      return $themeConfig.app.appLogoImage;
    },

    currentGgent() {
      if (this.agent) {
        return this.agent
      }
      return {}
    }
  },
  methods: {
    showGroupPage() {
      this.$router.push({
        name: 'organisation-group-public',
        params: {
          id: this.currentGroupId
        },
        query: {
          token: this.currentGroup.public_access_token,
          agent: this.agent.id
        }
      })
    }
  },
};
</script>

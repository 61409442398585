<template>
  <div>
    <b-row class="match-height mt-2">
      <b-col
        lg="12"
        md="12"
      >
        <div class="mt-2">
          <h3 class="float-left">Liste des documents du groupe</h3>
          <b-button variant="success" v-if="currentRole._isOrganizationRole()" @click=showDocumentUpload class="float-right "> Importer</b-button>
          <!--        <b-button variant="info" class="float-right mr-1"  v-b-modal.add-group-billing> Ajouter </b-button>-->
        </div>
      </b-col>
    </b-row>
  <b-card>


    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="documents"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      styleClass="vgt-table striped">
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->


        <span
          v-if="props.column.field === 'visibility' && currentRole._isOrganizationRole()"
          class="text-nowrap"
        >
        <span v-if=props.row.is_org_only>Limité à l'organisation</span>
        <span v-else>Tous</span>

      </span>

      <span
          v-if="props.column.field === 'size'"
          class="text-nowrap"
        >

        <span class="text-nowrap">{{ Math.round(props.row.size / 1024) }} KB</span>
      </span>

        <!-- Column: Action -->
      
        <span v-else-if="props.column.field === 'action'">
          <b-button
            variant="outline-info"
            class="ml-1"
            size="sm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="openLink(props.row)"
          >
            <span class="text-nowrap">
              <feather-icon
                icon="EyeIcon"
                size="14"
                
              />
              <span class="ml-1">Consulter</span>
            </span>
            
          </b-button>
          <b-button
            v-if="currentRole._isOrganizationRole()"
            variant="outline-danger"
            class="ml-1"
            size="sm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="$emit('delete', props.row)"
          >
          
            <span class="text-nowrap">
              <feather-icon
                icon="Trash2Icon"
                size="14"
                
              />
              <span class="ml-1">Supprimer</span>
            </span>
            
          </b-button>
          <b-button variant="outline-primary"
            class="ml-1"
            size="sm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="forceFileDownload(props.row)">
            <span class="text-nowrap">
              <feather-icon
                icon="DownloadIcon"
                size="14"
                
              />
            </span>
            <span class="ml-1">Télécharger</span>
          </b-button>
        </span>

        
    
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Indiquer 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> des {{ props.total }} données </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
  <UploadDocumentModal 
    ref="upload-document-modal"
    :current-group-id="currentGroupId"
    @monica-document-saved="$emit('reload')"
  />
  </div>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";

import Ripple from 'vue-ripple-directive'
import UploadDocumentModal from '@/views/groups/group/GroupDocuments/UploadDocumentModal.vue'

export default {
  components: {
    BCard,
    BRow, BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    UploadDocumentModal
  },
    directives: {
    Ripple,
  },
  props: {
      currentGroupId: {
        type: String,
        required: true,
      },
      documents: Array,
  },
  setup() {
    const {refOf} = useApplicationContext();
    const CURRENT_ROLE = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.role;
    const currentRole = new Roles(CURRENT_ROLE?.role || Roles.GUEST.role);

    const openLink = (document) => {
      window.open(document.link);

    }

    const forceFileDownload = (reponse) =>{
      try{
        const url = reponse.link;
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.png') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (e) {
        console.error(e)
      }
    }

    const showDocumentUpload = () => {
      refOf(`upload-document-modal`).$children[0].show();
    };

    let columns = [{
          label: 'Nom',
          field: 'name',
        },{
          label: 'Type',
          field: 'type',
        },{
          label: 'Taille',
          field: 'size',
        },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    
    if (currentRole._isOrganizationRole()) {
      columns.splice(3,0,{
        label: 'Visibilité',
        field: 'visibility',
      })
    }


    return {
      openLink,
      showDocumentUpload,
      currentRole,
      columns,
      forceFileDownload
    }
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      searchTerm: '',
      typeOptions: [
        'TRANSPORT',
        'all_inclusive'
      ],
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      planOptions: [],
     
    }
  },
  computed: {


  },
  methods: {

  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

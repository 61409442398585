<template>
  <div>
    <public-group-header :agent="currentAgent" :agencyName="tenantConfig.name" />
    <b-card class="mt-2 ml-2 mr-2">
      <b-tabs pills v-model="tabIndex">
        <b-tab active>
          <template #title>
            <feather-icon icon="CompassIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-md-inline">Sommaire</span>
          </template>
          <b-overlay :show="show" opacity="0.85" rounded="sm">
            <GroupSummary
              :current-group="currentGroup"
              :current-packages="currentPackages"
              :unavailable-packages="unavailablePackages"
              :current-accommodations="currentAccommodations"
              :available-agents="agents"
              :default-agent-id="currentAgentId"
              :show-reservation-button="showReservationButton"
              :show-status-section="false"
              :show-package-accommodation-tab="false"
              :show-registration-link="false"
              @book="createNewReservation($event)"
            />
          </b-overlay>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="MapIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-md-inline">Itinéraire</span>
          </template>
          <GroupItinerary
            :current-group="currentGroup"
            :current-itinerary="currentItinerary"
            :group-id="currentGroupId"
          />
        </b-tab>

        <!-- <b-tab>
          <template #title>
            <feather-icon icon="FileIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-md-inline">Documents</span>
          </template>
          <GroupDocuments
            :current-group-id="currentGroupId"
            :current-group="currentGroup"
            :documents="documents"
          />
        </b-tab> -->
      </b-tabs>
    </b-card>
  </div>
</template>


<script>
import { v4 as uuidv4 } from "uuid";
import {
  BRow,
  BCard,
  BCardText,
  BTabs,
  BTab,
  BCol,
  BOverlay,
} from "bootstrap-vue";

import GroupRoomingList from "@/views/groups/group/GroupRoomingList/GroupRoomingList";
import GroupReservations from "@/views/groups/group/GroupReservations/GroupReservations";
import PublicGroupHeader from "@/views/public/PublicGroupHeader";

import GroupPackages from "@/views/groups/group/GroupPackages/GroupPackages.vue";
import GroupSummary from "@/views/groups/group/GroupSummary/GroupSummary.vue";
import GroupItinerary from "@/views/groups/group/GroupItinerary/GroupItinerary.vue";
import GroupBillingSheets from "@/views/groups/group/GroupBilling/GroupBillingSheets.vue";
import GroupInformation from "@/views/groups/group/GroupInformation/GroupInformation.vue";
import GroupInventory from "@/views/groups/group/GroupInventory/GroupInventory.vue";
import GroupDocuments from "@/views/groups/group/GroupDocuments/GroupDocuments.vue";

import groupProduct from "@/views/groups/group/GroupBilling/GroupProduct/GroupProduct";

import { useGroupApi } from "@/modules/group/composables/use-group-api";

import { GroupService } from "@/views/groups/services/GroupService.js";
export const groupService = new GroupService();

import { GroupReservationService } from '@/views/groups/services/GroupReservationService.js'
export const groupReservationService = new GroupReservationService();

import { GroupPackagesService } from "@/views/groups/services/GroupPackagesService.js";
export const groupPackagesService = new GroupPackagesService();

import { GroupItineraryService } from "@/views/groups/services/GroupItineraryService.js";
export const groupItineraryService = new GroupItineraryService();

import { AUTH_STORE_NAMESPACE, SET_ORG_SETTINGS_ACTION } from "@/modules/authnz/models/store";

import { reactive, onMounted } from "@vue/composition-api";
import {useOrganizationApi} from "@/modules/authnz/composables/use-organization-api";

import store from "@/store";
import Roles from "@/modules/authnz/models/role";
import { useLocalisation } from "@/shared/composables/use-localisation";
import mixpanel from "mixpanel-browser";
import Tenant from "@/plugins/tenant";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BRow,
    BCol,
    BTab,
    BOverlay,
    GroupRoomingList,
    GroupReservations,
    GroupPackages,
    GroupSummary,
    GroupItinerary,
    GroupInventory,
    GroupBillingSheets,
    GroupInformation,
    GroupDocuments,
    groupProduct,
    PublicGroupHeader
  },
  setup(props, context) {
    const { t } = useLocalisation();
    const { getDocuments, deleteDocument } = useGroupApi();

    const publicAccessToken = context.root.$route.query.token;
    const {getOrganizationSettings} = useOrganizationApi();

    let tenantConfig = reactive({
      name: '',
    });

    try {
      tenantConfig.name =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.name;
    } catch (e) {
      console.error(e);
    }

    onMounted(async() => {
      try {
        let settings = await getOrganizationSettings(publicAccessToken);
        store.dispatch(`${AUTH_STORE_NAMESPACE}/${SET_ORG_SETTINGS_ACTION}`, {settings});
      } catch (e) {
        console.error('Error: trying loading org config:', e)
      }
    })

    return {
      t,
      tenantConfig,
      getDocuments,
      deleteDocument,
      publicAccessToken,
    };
  },
  data() {
    return {
      componentsKey: uuidv4(),
      currentAgentId: '',
      currentAgent: {},
      currentGroup: {},
      currentTransportations: {},
      currentAccommodations: {},
      currentGroupId: null,
      currentPackages: [],
      unavailablePackages: [],
      currentItinerary: [],
      documents: [],
      agents: [],
      Roles,
      show: true,
      tabIndex: 0,
      tabs: ["#summary"],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash);
    });
  },
  created: function () {
    this.currentGroupId = this.$route.params.id;
    this.currentAgentId = this.$route.query.agent

    if (this.currentAgentId) {
      return groupReservationService
        .getUser(this.currentAgentId, this.publicAccessToken)
        .then((response) => {
          this.currentAgent = response.data;
        }).then(() => {
          return this.retrieveInventories();
        })
    } else {
      return this.retrieveInventories();
    }
  },
  computed: {
    showReservationButton() {
      const tenantId = Tenant.resolveTenantId();
      return tenantId !== "transat";
    },
  },
  methods: {
    regenerateComponentsUuid() {
      return this.retrieveInventories().finally(() => {
        this.componentsKey = uuidv4();
      });
    },
    retrieveInventories() {
      return new Promise((resolve, reject) => {
        groupService
          .getGroup(this.currentGroupId, this.publicAccessToken)
          .then((response) => {
            this.currentGroup = response;
            store.commit(
              "app-groups/setCurrentGroupName",
              this.currentGroup.name
            );
            store.commit("app-groups/setCurrentGroupId", this.currentGroup.id);

            this.show = false;
            mixpanel.track("Access group - Public page", {
              group: response,
            });

            return groupService.getTransportations(
              this.currentGroupId,
              this.publicAccessToken
            );
          })
          .then((response) => {
            this.currentTransportations = response.data;

            return groupService.getAccommodations(
              this.currentGroupId,
              this.publicAccessToken
            );
          })
          .then((response) => {
            this.currentAccommodations = response.data;

            return groupPackagesService.getPackages(
              this.currentGroupId,
              this.publicAccessToken
            );
          })
          .then((response) => {
            this.currentPackages = response.data;

            let backorderPackages = [];

            try {
              response.data.forEach((pkg) => {
                let hasRoomsLeft = true;
                let hasTransportationLeft = true;

                pkg.rooms.forEach((room) => {
                  if (room.room_remaining_inventory.remaining_places <= 0) {
                    hasRoomsLeft = false;
                  }
                });

                if (
                  pkg.transportation.transportation_remaining_seats
                    .remaining_seats <= 0
                ) {
                  hasTransportationLeft = false;
                }

                if (!hasRoomsLeft || !hasTransportationLeft) {
                  backorderPackages.push(pkg.id);
                }
              });

              this.unavailablePackages = backorderPackages;
            } catch (e) {
              console.error(e);
            }

            return groupItineraryService.getItinerary(
              this.currentGroupId,
              this.publicAccessToken
            );
          })
          .then((response) => {
            this.currentItinerary = response.data;

            resolve(true);
          })
          .then(() => {
            this.getDocuments(this.currentGroupId, this.publicAccessToken).then(
              (response) => {
                this.documents = response;
              }
            );
          })
          .then(() => {
            return groupReservationService.getAllMembersOfOrg(this.publicAccessToken)
              .then(response => {
                this.agents = response.data
              });
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    async createNewReservation(request) {
      const reservation = {
        insurance_contract_number: null,
        insurer_name: null,
        insurance_types: ["medical"],
        has_trip_insurance: false,
        has_membership: false,
        extra_information: "",
        wants_package_modification: true,
        membership_number: null,
        membership_company: null,
        wants_insurance_submission: false,
        seat_selection: null,
        status: "DRAFT",
        contact_email: request.form_data.email,
        contact_phone: request.form_data.phone,
        user_id: request.form_data.agent_id,
      };

      const response = await groupReservationService.postReservationAll(
        this.currentGroupId,
        reservation,
        this.publicAccessToken
      );
      const match = response.headers.location.split("/");
      let reservationId = match[7];
      reservationId = reservationId.slice(0, reservationId.indexOf('?'));
      
      const reservationAccessToken = match[7].split('?token=')[1];


      this.$router.push({
        name: "group-reservation-public",
        params: {
          id: this.currentGroupId,
          reservation_id: reservationId,
        },
        query: {
          token: reservationAccessToken,
          package_id: request.package_id,
        },
      });
    },
  },
};
</script>

<style>
</style>

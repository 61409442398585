<template>
  <div>

  <b-card>

  <div class="mt-1">
    <b-row class="match-height mt-2">
      <b-col cols="6">
        <TransportOptionsInventory :current-group="currentGroup" :transport-options="transportOptions" />
      </b-col>
      <b-col cols="6">
        <AccommodationOptionsInventory :current-group="currentGroup" :accommodation-options="accommodationOptions" />
      </b-col>
    </b-row>

  </div>
  </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton } from 'bootstrap-vue'
import TransportOptionsInventory from './TransportOptionsInventory.vue'
import AccommodationOptionsInventory from './AccommodationOptionsInventory.vue'


export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    TransportOptionsInventory,
    AccommodationOptionsInventory

  },
  props: ['transportOptions','accommodationOptions','currentGroup'],
  data() {
    return {
      currentReservation: {},
      currentPackages: [],
      tabIndex: 0,
      activateTabs: true,
    }
  },
  created: function () {

  },
  methods: {

  }

}
</script>

<style>

</style>

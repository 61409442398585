<template>
    <b-row class="match-height">
      <b-col         
        lg="12"
        md="12"
      >
        <group-basic-information-card :can-edit="canEdit"  :group-id="currentGroupId"/>
        <group-advanced-information-card :can-edit="canEdit"  :group-id="currentGroupId" @monica-group-saved="updateGroup" />



      </b-col>
      <b-col
        lg="6"
        md="6"
      >
      </b-col>
    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel } from 'bootstrap-vue'
import GroupBasicInformationCard from "@/modules/group/components/GroupBasicInformationCard";
import GroupAdvancedInformationCard from "@/modules/group/components/GroupAdvancedInformationCard";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {ref, onMounted} from '@vue/composition-api';


export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupBasicInformationCard,
    GroupAdvancedInformationCard
  },
    props: {
    currentGroup: Object,
    currentGroupId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hasBasicInfo: false,
    }
  },
  setup(props, {emit}) {
    const {forceUpdate} = useApplicationContext();

    
    const updateGroup = () => {
      emit('monica-group-updated')
    }

    return {
      updateGroup
    }
    
  }
}
</script>

<style>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Recherche","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),(_vm.canEdit)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","to":{ name: 'group-add-package' }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Créer un forfait")])]):_vm._e()],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.packages,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'display_order')?_c('span',{staticClass:"small"},[_c('b-button',{attrs:{"variant":"link","size":"sm","disabled":props.row.originalIndex === 0},on:{"click":function($event){return _vm.movePackageUp(props.row.id)}}},[_c('feather-icon',{attrs:{"size":"18","icon":"ArrowUpIcon"}})],1),_c('b-button',{attrs:{"variant":"link","size":"sm","disabled":props.row.originalIndex === _vm.packages.length - 1},on:{"click":function($event){return _vm.movePackageDown(props.row.id)}}},[_c('feather-icon',{attrs:{"size":"18","icon":"ArrowDownIcon"}})],1)],1):(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('router-link',{staticClass:"text-nowrap",staticStyle:{"cursor":"pointer"},attrs:{"to":{
              name: 'group-edit-package',
              params: { package_id: props.row.id, id: _vm.groupId },
            },"tag":"span"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])])],1):(props.column.field === 'is_public')?_c('span',{staticClass:"text-nowrap text-center mx-auto"},[(props.row.is_public)?_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:(
                'Ce forfait est visible pour les clients externes'
              ),expression:"\n                'Ce forfait est visible pour les clients externes'\n              ",modifiers:{"hover":true,"top":true,"html":true}}],staticClass:"text-success",attrs:{"size":"18","icon":"EyeIcon"}})],1):_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:(
                'Ce forfait ne sera pas affiché pour les clients externes'
              ),expression:"\n                'Ce forfait ne sera pas affiché pour les clients externes'\n              ",modifiers:{"hover":true,"top":true,"html":true}}],staticClass:"text-grey",attrs:{"size":"18","icon":"EyeIcon"}})],1)]):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant('draft')}},[_vm._v(" "+_vm._s(_vm.statusLabel("draft"))+" ")])],1):(props.column.field === 'type')?_c('span',[_c('feather-icon',{staticClass:"mr-50",class:("text-" + (_vm.typeVariant(props.row.type))),attrs:{"icon":_vm.typeIcons(props.row.type),"size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(_vm.typeLabel(props.row.type)))])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.canEdit)?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'group-edit-package',
                  params: { package_id: props.row.id, id: _vm.groupId },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Modifier")])],1):_vm._e(),(_vm.canEdit)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deletePackage(_vm.groupId, props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Supprimer")])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Indiquer 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" des "+_vm._s(props.total)+" données ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_vm._v(" > ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
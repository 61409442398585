import axios from '@axios'

export class GroupItineraryService {

  constructor() {}

  async getItinerary(group_id, publicAccessToken) {
    let url = `/groups/${group_id}/itineraries`

    if (publicAccessToken != null) {
      url = `/groups/${group_id}/itineraries?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

}

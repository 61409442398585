import axios from "@axios";

export class GroupRoomingListService {
  constructor() {}

  async getRoomingList(group_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${group_id}/reservations`)
        .then((response) => {
          let roomingList = this.extractRoomingListFromReservations(
            response.data
          );
          resolve(roomingList);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  extractRoomingListFromReservations(reservations) {
    let travelersList = [];
    let travelIndex = 1;

    reservations.forEach((reservation) => {
      if (
        reservation.status != "CANCELLED" &&
        reservation.package_reservations.length > 0
      ) {
        let reservationNumber = reservation.reservation_number; //TODO: Should have a field on the reservation object

        reservation.package_reservations.forEach((package_reservation) => {
          let packageName = package_reservation.group_package.name;
          let packageOccupation = package_reservation.occupation_type.type;

          package_reservation.travelers.forEach((traveler) => {
            let travelerName = `${traveler.first_name} ${traveler.last_name}`;

            if (traveler.middle_name) {
              travelerName = `${traveler.first_name} ${traveler.middle_name} ${traveler.last_name}`;
            }

            let travelDob = this.formatDateFromArray(traveler.birthday);
            let travelerObject = {
              index: travelIndex,
              reservation_number: reservationNumber,
              traveler_name: travelerName,
              gender: traveler.gender,
              dob: travelDob,
              package_name: packageName,
              occupation: packageOccupation,
            };

            travelersList.push(travelerObject);
            travelIndex++;
          });
        });
      }
    });

    return travelersList;
  }

  async exportRoomingList(group_id, fields) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/groups/${group_id}/passengers`,
        method: "POST",
        responseType: "blob",
        data: fields,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  formatDateFromArray(dateArray) {
    if (dateArray && dateArray.length == 3) {
      //Add O if single Digit Month

      if (dateArray[1].toString().length == 1) {
        dateArray[1] = "0" + dateArray[1].toString();
      }
      //Add O if single Digit Day
      if (dateArray[2].toString().length == 1) {
        dateArray[2] = "0" + dateArray[2].toString();
      }

      return `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;
    } else {
      return null;
    }
  }
}
